import React from 'react';
import { useAppContext } from './utils/AppProvider';
import CurrencyInput from "./components/CurrencyInput";
import CurrencyChart from "./components/CurrencyChart";
import Background from "./components/Background";
import History from "./components/History";

function App() {
    const { amount1, amount2, currency1, currency2, rates, handleAmountChange, handleCurrencyChange } = useAppContext();

    return (
        <>
            <Background />
            <History />
            <div className="inputs">
                <CurrencyInput value={amount1}
                               onValueChange={(value) => handleAmountChange(value, 'amount1')}
                               currency={currency1}
                               onCurrencyChange={(currency) => handleCurrencyChange(currency, 'currency1')}
                               currencies={Object.keys(rates)}/>
                <CurrencyInput value={amount2}
                               onValueChange={(value) => handleAmountChange(value, 'amount2')}
                               currency={currency2}
                               onCurrencyChange={(currency) => handleCurrencyChange(currency, 'currency2')}
                               currencies={Object.keys(rates)}/>
            </div>
            <CurrencyChart currency1={currency1} currency2={currency2}/>
        </>

    );
}

export default App;
