import React from "react";
import './index.css';
import {useAppContext} from "../../utils/AppProvider";

const History = () => {
    const { history, handleHistorySelect } = useAppContext(); // Consume the history from context

    if (history.length === 0) {
        return null; // Don't display anything if history is empty
    }

    return (
        <div className="history">
            <span>Conversion History</span>
            <div className="history-container">
                {history.map((entry, index) => (
                    <button key={index} onClick={() => handleHistorySelect(entry)} style={{cursor: 'pointer'}}>
                        {entry}
                    </button>
                ))}
            </div>
        </div>
    );

}

export default History;