import React, { createContext, useContext, useState, useEffect } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [amount1, setAmount1] = useState('');
    const [amount2, setAmount2] = useState('');
    const [currency1, setCurrency1] = useState(localStorage.getItem('currency1') || 'USD');
    const [currency2, setCurrency2] = useState(localStorage.getItem('currency2') || 'RUB');
    const [rates, setRates] = useState(JSON.parse(localStorage.getItem('rates')) || {});
    const [activeField, setActiveField] = useState(null);
    const [history, setHistory] = useState(JSON.parse(localStorage.getItem('currencyHistory')) || []);

    useEffect(() => {
        const loadRates = async () => {
            if (navigator.onLine) {
                const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${currency1}`);
                const data = await response.json();
                setRates(data['rates']);
                localStorage.setItem('rates', JSON.stringify(data['rates']));
                localStorage.setItem('currency1', currency1);
            } else {
                setRates(JSON.parse(localStorage.getItem('rates')) || {});
            }
        };

        loadRates();
    }, [currency1]);

    useEffect(() => {
        if (activeField === 'amount1') {
            setAmount2(Number((parseFloat(amount1) * (rates[currency2] || 0)).toFixed(2)).toString());
        } else if (activeField === 'amount2') {
            setAmount1(Number((parseFloat(amount2) / (rates[currency2] || 1)).toFixed(2)).toString());
        }
    }, [rates, currency1, currency2, amount1, amount2, activeField]);

    const updateHistory = (currency1, currency2) => {
        const newHistoryEntry = `${currency1} to ${currency2}`;
        const filteredHistory = history.filter(entry => entry !== newHistoryEntry);
        const updatedHistory = [...filteredHistory, newHistoryEntry].slice(-5);
        setHistory(updatedHistory);
        localStorage.setItem('currencyHistory', JSON.stringify(updatedHistory));
    };


    const handleAmountChange = (amount, field) => {
        if (field === 'amount1') {
            setAmount1(amount);
            setActiveField('amount1');
            setAmount2(amount !== '' ? Number((parseFloat(amount) * (rates[currency2] || 0)).toFixed(2)).toString() : '');
        } else {
            setAmount2(amount);
            setActiveField('amount2');
            setAmount1(amount !== '' ? Number((parseFloat(amount) / (rates[currency2] || 1)).toFixed(2)).toString() : '');
        }
        if (amount.trim() !== '') {
            updateHistory(currency1, currency2);
        }
    };

    const handleCurrencyChange = (currency, field) => {
        if (field === 'currency1') {
            setCurrency1(currency);
            localStorage.setItem('currency1', currency);
        } else {
            setCurrency2(currency);
            localStorage.setItem('currency2', currency);
        }
    };
    const handleHistorySelect = (historyEntry) => {
        const [selectedCurrency1, selectedCurrency2] = historyEntry.split(' to ');
        setCurrency1(selectedCurrency1);
        setCurrency2(selectedCurrency2);
        // Optionally, trigger a conversion calculation or any other required updates
    };

    return (
        <AppContext.Provider value={{
            amount1,
            amount2,
            currency1,
            currency2,
            rates,
            history,
            handleHistorySelect,
            handleAmountChange,
            handleCurrencyChange
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
