import React from 'react';
import './index.css';

function CurrencyInput({ value, onValueChange, currency, onCurrencyChange, currencies }) {
    return (
        <div className="currency-input">
            <div className="currency-input-top">
                <span className='currency-input-currency'>{currency}</span>
                <select className='currency-input-select' value={currency} onChange={e => onCurrencyChange(e.target.value)}>
                    {currencies.map((currency) => (
                        <option key={currency} value={currency}>{currency}</option>
                    ))}
                </select>
            </div>
            <input className='currency-input-input' placeholder={`Amount in ${currency}`} type="number" value={value}
                   onChange={e => onValueChange(e.target.value)}/>
        </div>
    );
}

export default CurrencyInput;
