import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import {AppProvider} from "./utils/AppProvider";

serviceWorker.register();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppProvider>
        <App/>
    </AppProvider>
);

reportWebVitals();
