import {useEffect, useState} from "react";

export const useChartData = (currency1, currency2) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [hasData, setHasData] = useState(false);

    const processChartData = (data, currency2) => {
        const sortedDates = Object.keys(data.rates).sort();
        const labels = sortedDates.map(date => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
        const values = sortedDates.map(date => data.rates[date][currency2]);

        return {
            labels,
            datasets: [
                {
                    label: `${currency2} Rate`,
                    data: values,
                    borderColor: 'rgb(237,236,243)',
                    backgroundColor: 'rgb(17,17,17)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    tension: 0.3
                },
            ],
        };
    };

    const fetchData = async (currency1, currency2, startDate, endDate) => {
        const apiUrl = `https://api.frankfurter.app/${startDate}..${endDate}?from=${currency1}&to=${currency2}`;
        const response = await fetch(apiUrl);
        return await response.json();
    };

    useEffect(() => {
        const endDate = new Date().toISOString().split('T')[0];
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        const formattedStartDate = startDate.toISOString().split('T')[0];

        const loadChartData = async () => {
            try {
                const data = await fetchData(currency1, currency2, formattedStartDate, endDate);
                if (data && data.rates && Object.keys(data.rates).length > 0) {
                    const processedData = processChartData(data, currency2);
                    setChartData(processedData);
                    setHasData(true);
                } else {
                    setHasData(false);
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
                setHasData(false);
            }
        };

        loadChartData();
    }, [currency1, currency2]);

    return { chartData, hasData };
};