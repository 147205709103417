import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useChartData} from "../../utils/useChartData";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const chartOptions = {
    plugins: { legend: { display: false } },
    responsive: true,
    maintainAspectRatio: false,
};

const CurrencyChart = ({ currency1, currency2 }) => {
    const { chartData, hasData } = useChartData(currency1, currency2);

    return (
        <div className={`chart ${hasData ? '' : 'no-data'}`}>
            {hasData ? (
                <div className='chart-wrapper'>
                    <Line data={chartData} options={chartOptions} />
                </div>
            ) : (
                <span className="no-data-span">No chart data available</span>
            )}
        </div>
    );
};

export default CurrencyChart;
