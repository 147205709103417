import React from "react";
import './index.css';

const Background = () => {
    return(
        <div className="background">

        </div>
    )
}

export default Background;